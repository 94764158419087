import React from 'react';
import PropTypes from 'prop-types';
import { openRegistrationModal } from '../../../../utils';
import './MoreVerticalsItem.scss';

const MoreVerticalsItem = ({ title, image, ctaTitle, promoCode }) => {
  const { desktopUrl, mobileUrl } = image;
  const handleClick = (e, promo) => {
    e.preventDefault();
    openRegistrationModal('', promo);
  };
  return (
    <li className="more_verticals_item">
      <h3>{title}</h3>
      <a className="image" href="" title={ctaTitle} onClick={e => handleClick(e, promoCode)}>
        <picture>
          <source type="image/png" srcSet={desktopUrl} media="(min-width: 1024px)" />
          <img srcSet={mobileUrl} decoding="auto" alt="" loading="lazy" />
        </picture>
      </a>
      <a
        className="button"
        data-testid="more_verticals_item_button"
        onClick={e => handleClick(e, promoCode)}
        href=""
        title={ctaTitle}
      >
        {ctaTitle}
      </a>
    </li>
  );
};

MoreVerticalsItem.propTypes = {
  title: PropTypes.string.isRequired,
  ctaTitle: PropTypes.string.isRequired,
  promoCode: PropTypes.string.isRequired,
  image: PropTypes.shape({
    desktopUrl: PropTypes.string,
    mobileUrl: PropTypes.string,
  }).isRequired,
};

export default MoreVerticalsItem;
