import React from 'react';
import { useServerData } from '../../state/serverDataContext';
import MoreVerticalsItem from './components/MoreVerticalsItem';
import './MoreVerticalsContainer.scss';

const MoreVerticalsContainer = () => {
  const { moreVerticalsData } = useServerData(serverData => ({
    moreVerticalsData: serverData.pageData?.moreVerticals || {},
  }));

  if (!moreVerticalsData?.verticalsList?.length) return null;

  return (
    <article className="more_verticals_container" data-testid="more_verticals_container">
      <h3 className="more_verticals_title">{moreVerticalsData.title}</h3>
      <ul className="more_verticals_list">
        {moreVerticalsData.verticalsList.map(item => (
          <MoreVerticalsItem
            key={item.title.split(' ').join('')}
            title={item.title}
            image={item.image}
            ctaTitle={item.ctaTitle}
            promoCode={item.promoCode}
          />
        ))}
      </ul>
    </article>
  );
};

export default MoreVerticalsContainer;
